import type { DisplayOptions } from '@/types/display'
import {
    DayOfWeekNum as DayOfWeek,
    DayOfWeekNum,
    MS_PER_HOUR,
    toDayStringFormat,
} from '@planda/utils'
import { MS_PER_DAY } from '@planda/design-system'
import { addDays, isToday, startOfWeek } from 'date-fns'
import { Event, KanbanStatusEnum, Task, Task as TaskType, TemplateRecurEvent } from 'src/types'

type TimePeriodOptions = DisplayOptions & { weekStartsOn?: number }

export const isPast = (x: TaskType, i: number, arr: TaskType[], options?: TimePeriodOptions) => {
    const { weekStartsOn = 0, hideCompleted } = options || {}

    if (!x.completed) return false
    if (hideCompleted) return true

    // (!displayOptions.hideCompleted || !x.completed) &&

    const weekStart = startOfWeek(Date.now(), {
        weekStartsOn: weekStartsOn && ((weekStartsOn % 7) as DayOfWeek),
    })
    return x.completed < weekStart.getTime()
    // x.dateStart ? x.dateStart < weekStart.getTime() :
    // return x.dateStart && x.completed && (x.dateStart < (Date.now() - MS_PER_DAY * 5))
}
// starting on startOfWeek
export const isFutureTask = (
    x: TaskType,
    i: number,
    arr: TaskType[],
    options?: TimePeriodOptions
) => {
    if (isPast(x, i, arr, options)) return false
    if (x.dateHandedOut) {
        return x.dateHandedOut > Date.now()
    }
    if (x.status === KanbanStatusEnum.Enum.somedayMaybe) return true
    if (!x.dateStart)
        // TODO: below is for sequential tasks, now groups aren't sequential
        // if (x.category.split('/').length >= 3) {
        //     if (!x.dateStart) return true
        //     const sameGroup = arr.filter((y, j, arr2) => !y.completed && y.category === x.category && !isPast(y, j, arr2, options))
        //     if (sameGroup.some(x => !x.dateStart)) {
        //         return sameGroup.some((y) => y.name < x.name)
        //     }
        //     // const uniqDates = uniqBy(sameGroup, (y) => y.dateStart)
        //     // if (uniqDates.length === 1) return sameGroup.some((y) => y.name < x.name)
        //     return sameGroup.some((y, j, arr2) => x.id !== y.id && y.dateStart! < x.dateStart!)
        // }
        return false
    if (x.dateStart > Date.now() + MS_PER_DAY * 25) return true
    return false
}

/**
 * Return task progress between 0-100
 * @param item
 * @returns
 */
export function taskProgress(item: TaskType) {
    if (item.completed) return 100
    return item.subtasks && item.subtasks.length > 0
        ? (item.subtasks.reduce(
              (previousValue, currentValue) => previousValue + (currentValue.completed ? 1 : 0),
              0
          ) *
              100) /
              item.subtasks.length
        : 0
}

export const orderTasks = (a: Task, b: Task, defaultSort?: (a: Task, b: Task) => number) => {
    if (a.order === b.order) {
        return defaultSort ? defaultSort(a, b) : b.createdAt - a.createdAt
    } else if (a.order === undefined) {
        return 1
    } else if (b.order === undefined) {
        return -1
    } else {
        return a.order - b.order
    }
}

const sortByFn: { [x: string]: (item: TaskType) => number } = {
    progress: taskProgress,
}

export const defaultSortTasks = (
    items: Task[],
    displayOptions: DisplayOptions,
    timePeriod?: number
) => {
    return items.toSorted((a1, b1) =>
        orderTasks(a1, b1, (a, b) => {
            const isReallyCompleted = (x: TaskType) => x.completed && !isToday(x.completed)
            if (timePeriod === 0 && a.completed && b.completed) {
                return b.completed - a.completed
            }

            if (isReallyCompleted(a) && !isReallyCompleted(b)) {
                return 1
            }
            if (isReallyCompleted(b) && !isReallyCompleted(a)) {
                return -1
            }

            if (sortByFn[displayOptions.sortBy]) {
                const fn = sortByFn[displayOptions.sortBy]
                return (fn(a) - fn(b)) * (displayOptions.ascending ? 1 : -1)
            }
            return (
                // @ts-expect-error
                (a[displayOptions.sortBy] - b[displayOptions.sortBy]) *
                (displayOptions.ascending ? 1 : -1)
            )
        })
    )
}

export const getWorkBlockId = (
    event: Pick<Event, 'id'> | Pick<TemplateRecurEvent, 'cron' | 'dateStart' | 'id'>,
    rootEventId?: Event['id']
) => {
    if (!rootEventId) rootEventId = event.id
    return (
        rootEventId +
        (event.id !== rootEventId || (event as TemplateRecurEvent).cron
            ? '#' + toDayStringFormat((event as TemplateRecurEvent).dateStart)
            : '')
    )
}

export const toWeekAssigned = ({
    dateInWeek,
    weekStartsOn,
}: {
    dateInWeek: Date | number
    weekStartsOn: DayOfWeekNum
}) => {
    const weekStartDate = startOfWeek(dateInWeek, { weekStartsOn })
    return {
        weekStartDate,
        weekId: toDayStringFormat(weekStartDate),
    }
}

const isTaskAssignedToWeek = ({
    task,
    dateInWeek,
    weekStartsOn,
}: {
    task: Pick<TaskType, 'weeksAssigned'>
    dateInWeek: Date | number
    weekStartsOn: DayOfWeek
}) => {
    if (!task.weeksAssigned) return false

    const { weekStartDate: lastWeekDate, weekId: lastWeek } = toWeekAssigned({
        dateInWeek,
        weekStartsOn,
    })

    if (task.weeksAssigned.includes(lastWeek)) return true

    const toCloseWeekKey = (offset: number) => toDayStringFormat(addDays(lastWeekDate, offset))

    // also check adjacent days just in case user changed weekStartsOn
    if (task.weeksAssigned.includes(toCloseWeekKey(1))) {
        return true
    }
    if (task.weeksAssigned.includes(toCloseWeekKey(-1))) {
        return true
    }

    return false
}

export const isLastWeekTask = ({
    task,
    weekStartsOn,
}: {
    task: Pick<TaskType, 'weeksAssigned'>
    weekStartsOn: DayOfWeek
}) => {
    return isTaskAssignedToWeek({
        task,
        dateInWeek: Date.now() - MS_PER_DAY * 7,
        weekStartsOn,
    })
}

export const isThisWeekTask = ({
    task,
    weekStartsOn,
}: {
    task: TaskType
    weekStartsOn: DayOfWeek
}) =>
    isTaskAssignedToWeek({
        task,
        dateInWeek: Date.now(),
        weekStartsOn,
    })

export const isNextWeekTask = ({
    task,
    weekStartsOn,
}: {
    task: TaskType
    weekStartsOn: DayOfWeek
}) =>
    isTaskAssignedToWeek({
        task,
        dateInWeek: Date.now() + MS_PER_DAY * 7,
        weekStartsOn,
    })

export const isOverdueTask = (task: TaskType) => {
    const isOverdue = !!(!task.completed && task.dateStart && task.dateStart < Date.now())
    return isOverdue
}

const isUrgentTask = (task: TaskType) => {
    const isOverdue = isOverdueTask(task)

    return !!(
        !isOverdue &&
        !task.completed &&
        task.dateStart &&
        (task.dateStart - Date.now() < MS_PER_HOUR * 5 || isToday(task.dateStart))
    )
}
