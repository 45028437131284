import { toNewItem } from '@/types/newItem'
import { dynamodbUpdateItemOptimistic } from '@/utils/dynamodb'
import { ThunkDispatch } from '@reduxjs/toolkit'
import { EndpointBuilder } from '@reduxjs/toolkit/query'
import { UpdateItemParams } from 'dynamodb-helpers'
import { apiSlice } from './apiSlice'
import { isNil } from 'lodash'

const defaultInvalidateTags =
    <T>(tag: TagType, keyBy?: keyof T | null) =>
    (_: unknown, __: unknown, item: T) => [
        { type: tag, id: !isNil(keyBy) ? item[keyBy] : undefined },
        { type: tag },
    ]

export const tagTypes = [
    'event',
    'task',
    'templateDB',
    'templateRecur',
    'category',
    'workBlock',
    'shareLink',
    'user',
    'profile',
    'categoryUnit',
    'friend',
    'goal',
    'goal-order',
    'theme-customizations',
    'idea',
    'feature-access',
    'rule-of-three',
    'achievement',
    'wallet',
    'lesson-data',
    'lessons-in-progress',
    'mascot-settings',
    'tiny-tasks',
    'kanban-settings',
    'habit',
    'habit-entry',
    'menstrual-cycle',
] as const
Object.freeze(tagTypes)

export type TagType = (typeof tagTypes)[number]

export type MutationLifeCycleApi = {
    dispatch: ThunkDispatch<any, any, any>
    queryFulfilled: Promise<unknown>
    getState: () => any
}

export const builderHelpers = (builder: EndpointBuilder<any, any, any>) => ({
    builderItemGET: builderItemGETMaker(builder),
    builderArrayGET: builderArrayGETMaker(builder),
    builderArrayItemPUT: builderArrayItemPUTMaker(builder),
    builderArrayItemPATCH: builderArrayItemPATCHMaker(builder),
    builderItemPATCH: builderItemPATCHMaker(builder),
    builderItemPUT: builderItemPUTMaker(builder),
    defaultInvalidateTags,
})

export const builderItemGETMaker =
    (builder: EndpointBuilder<any, any, any>) =>
    <T, A extends string | void = void>({
        url,
        tag,
    }: {
        url: string | ((x: A) => string)
        tag: TagType
    }) =>
        builder.query<T, A>({
            query: (item) => (typeof url === 'string' ? url : url(item)),
            providesTags(result, error, arg, meta) {
                return [{ type: tag, id: arg }]
            },
        })
// getLessonData: builder.query<Record<string, any>, LessonId>({
// query: (lessonId) => `game/lesson/${lessonId}`,
// })

export const builderArrayGETMaker =
    (builder: EndpointBuilder<any, any, any>) =>
    <T extends { id: string }, A = void>({
        url,
        tag,
    }: {
        url: string | ((x: A) => string)
        tag: TagType
    }) =>
        builder.query<T[], A>({
            query: (item) => (typeof url === 'string' ? url : url(item)),
            providesTags(result, error, arg, meta) {
                return [{ type: tag }, ...(result || [])?.map((x) => ({ type: tag, id: x.id }))]
            },
        })

const putStartArray =
    <T extends { id: string }>(
        arrayEndpointName: string,
        tag: TagType,
        args?: (x: T) => any,
        updateArgs?: (x: T, originalArgs: any) => boolean
    ) =>
    async (arg: T, { dispatch, queryFulfilled, getState }: MutationLifeCycleApi) => {
        const patchedResults: any[] = []

        const pushInArgs = (originalArgs: any) => {
            const res = dispatch(
                // @ts-expect-error
                apiSlice.util.updateQueryData(endpointName, originalArgs, (data: any[]) => {
                    if (data.some((x) => x.id === arg.id))
                        return data.map((x) => (x.id === arg.id ? { ...x, ...toNewItem(arg) } : x))
                    return [...data, toNewItem(arg)]
                })
            )
            patchedResults.push(res)
        }

        if (updateArgs) {
            for (const { endpointName, originalArgs } of apiSlice.util.selectInvalidatedBy(
                getState(),
                [{ type: tag, id: arg.id }]
            )) {
                if (endpointName === arrayEndpointName && updateArgs(arg, originalArgs)) {
                    pushInArgs(originalArgs)
                }
            }
        } else {
            pushInArgs(args?.(arg))
        }

        queryFulfilled.catch(() => {
            patchedResults.forEach((patchedResult) => patchedResult && patchedResult.undo?.())
        })
    }

export const updateItemStart =
    <T extends {}>(endpointName: string, keyBy: string | null) =>
    async (
        arg: { updates: UpdateItemParams<T> },
        { dispatch, queryFulfilled }: Pick<MutationLifeCycleApi, 'dispatch' | 'queryFulfilled'>
    ) => {
        const patchedResults = [
            dispatch(
                apiSlice.util.updateQueryData(
                    // @ts-ignore
                    endpointName,
                    // @ts-ignore
                    keyBy === null ? undefined : (arg[keyBy] as string),
                    (data: T | undefined) => {
                        if (!data) return
                        dynamodbUpdateItemOptimistic(data, arg.updates)
                    }
                )
            ),
        ]
        queryFulfilled.catch(() => {
            // @ts-expect-error
            patchedResults.forEach((patchedResult) => patchedResult && patchedResult.undo?.())
        })
    }

export const putItemStart =
    <T extends {}>(endpointName: string, keyBy?: string | null) =>
    async (
        arg: T,
        {
            dispatch,
            queryFulfilled,
        }: { dispatch: ThunkDispatch<any, any, any>; queryFulfilled: Promise<unknown> }
    ) => {
        const patchedResults = [
            dispatch(
                apiSlice.util.updateQueryData(
                    // @ts-ignore
                    endpointName,
                    // @ts-ignore
                    isNil(keyBy) ? undefined : (arg[keyBy] as string),
                    (data: T | undefined) => {
                        return arg
                    }
                )
            ),
        ]
        queryFulfilled.catch(() => {
            // @ts-expect-error
            patchedResults.forEach((patchedResult) => patchedResult && patchedResult.undo?.())
        })
    }

const updateArrayStart =
    <T extends { id: string }>(endpointName: string, args?: (x: T) => any) =>
    async (
        arg: { updates: UpdateItemParams<T>; id: string },
        {
            dispatch,
            queryFulfilled,
        }: { dispatch: ThunkDispatch<any, any, any>; queryFulfilled: Promise<unknown> }
    ) => {
        const patchedResults = [
            dispatch(
                apiSlice.util.updateQueryData(
                    // @ts-expect-error
                    endpointName,
                    // @ts-ignore
                    args ? args(arg) : arg.id,
                    (data: T[]) => {
                        for (let x of data) {
                            if (x.id === arg.id) {
                                dynamodbUpdateItemOptimistic(x, arg.updates)
                            }
                        }
                    }
                )
            ),
        ]
        queryFulfilled.catch(() => {
            // @ts-expect-error
            patchedResults.forEach((patchedResult) => patchedResult && patchedResult.undo?.())
        })
    }

export const builderArrayItemPUTMaker =
    (builder: EndpointBuilder<any, any, any>) =>
    <T extends { id: string }>({
        url,
        method = 'PUT',
        tag,
        getArrayEndpointName,
        ...rest
    }: {
        url: string | ((x: T) => string)
        method?: 'POST' | 'PUT'
        tag: TagType
        getArrayEndpointName: string
    } & (
        | { getArrayEndpointArgs?: (x: T) => any }
        | { updateArgs?: (x: T, originalArgs: any) => boolean }
    )) =>
        builder.mutation<void, T>({
            query: (item: T) => ({
                url: typeof url === 'string' ? `${url}/${item.id}` : url(item),
                method,
                body: item,
            }),
            invalidatesTags: (result: unknown, error: unknown, item: T) => [
                { type: tag, id: item.id },
                { type: tag },
            ],
            onQueryStarted: putStartArray(
                getArrayEndpointName,
                tag,
                'getArrayEndpointArgs' in rest ? rest.getArrayEndpointArgs : undefined,
                'updateArgs' in rest ? rest.updateArgs : undefined
            ),
        })

export const builderArrayItemPATCHMaker =
    (builder: EndpointBuilder<any, any, any>) =>
    <T extends { id: string }, S = {}>({
        url,
        method = 'PATCH',
        tag,
        getArrayEndpointName,
        getArrayEndpointArgs,
    }: {
        url: string | ((x: { updates: UpdateItemParams<T>; id: string } & S) => string)
        method?: 'PATCH'
        tag: TagType
        getArrayEndpointName: string
        getArrayEndpointArgs?: (x: { updates: UpdateItemParams<T>; id: string }) => any
    }) =>
        builder.mutation<void, { updates: UpdateItemParams<T>; id: string } & S>({
            query: (item) => ({
                url: typeof url === 'string' ? `${url}/${item.id}` : url(item),
                method,
                body: item.updates,
            }),
            invalidatesTags: (result, error, item) => [{ type: tag, id: item.id }, { type: tag }],
            onQueryStarted: updateArrayStart(getArrayEndpointName, getArrayEndpointArgs),
        })

export const builderItemPATCHMaker =
    (builder: EndpointBuilder<any, any, any>) =>
    <T extends {}, S extends { [x: string]: string } = {}>({
        url,
        method = 'PATCH',
        tag,
        getItemEndpointName,
        keyBy,
    }: {
        url: string | ((x: { updates: UpdateItemParams<T> } & S) => string)
        method?: 'PATCH'
        tag: TagType
        getItemEndpointName: string
        keyBy?: keyof S | null
    }) =>
        builder.mutation<void, { updates: UpdateItemParams<T> } & S>({
            query: (item) => ({
                url: typeof url === 'string' ? url : url(item),
                method,
                body: item.updates,
            }),
            invalidatesTags: defaultInvalidateTags<S>(tag, keyBy),
            onQueryStarted: updateItemStart(getItemEndpointName, (keyBy ?? null) as string | null),
        })

export const builderItemPUTMaker =
    (builder: EndpointBuilder<any, any, any>) =>
    <T extends {}, S extends { [x: string]: string } = {}>({
        url,
        method = 'PUT',
        tag,
        getItemEndpointName,
        keyBy,
    }: {
        url: string | ((x: T & S) => string)
        method?: 'PUT' | 'POST'
        tag: TagType
        getItemEndpointName: string
        keyBy?: keyof S | null
    }) =>
        builder.mutation<void, T & S>({
            query: (item) => ({
                url: typeof url === 'string' ? url : url(item),
                method,
                body: item,
            }),
            invalidatesTags: defaultInvalidateTags<T & S>(tag, keyBy),
            onQueryStarted(arg, api) {
                putItemStart(getItemEndpointName, keyBy as string | null | undefined)(arg, api)
            },
        })
