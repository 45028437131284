import { memo, splitProps } from '../helpers.mjs';
import { createRecipe, mergeRecipes } from './create-recipe.mjs';

const taskBulletFn = /* @__PURE__ */ createRecipe('list-item', {}, [])

const taskBulletVariantMap = {
  "isSubtask": [
    "true"
  ],
  "size": [
    "xs",
    "sm"
  ],
  "isComplete": [
    "true"
  ],
  "isUnimportant": [
    "true"
  ],
  "disableHover": [
    "true"
  ],
  "disableContainer": [
    "true"
  ],
  "spacing": [
    "min"
  ],
  "limitWidth": [
    "true"
  ]
}

const taskBulletVariantKeys = Object.keys(taskBulletVariantMap)

export const taskBullet = /* @__PURE__ */ Object.assign(memo(taskBulletFn.recipeFn), {
  __recipe__: true,
  __name__: 'taskBullet',
  __getCompoundVariantCss__: taskBulletFn.__getCompoundVariantCss__,
  raw: (props) => props,
  variantKeys: taskBulletVariantKeys,
  variantMap: taskBulletVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, taskBulletVariantKeys)
  },
  getVariantProps: taskBulletFn.getVariantProps,
})