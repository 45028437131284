// Need to use the React-specific entry point to import createApi
import { CalendarItem } from '@/features/calendars/types'
import { dynamodbUpdateItemOptimistic } from '@/utils/dynamodb'
import {
    Category,
    CategoryRes,
    CategorySchema,
    DatedItem,
    DatedRes,
    Item,
    KanbanSettings,
    NewItem,
    Section,
    SectionSchema,
    Task,
    TodoGroup,
    TodoGroupSchema,
    getEmptyCategoryRes,
    getInfoFromCategoryPath,
    isDatedItem,
} from '@/types'
import { New } from '@/types/newItem'
import { WorkBlockInfo } from '@/types/workBlock'
import { getCategoryPath, parseCategoryUnitUpdates } from '@/utils/categories'
import { getCategoryOptimisticData, shallowRemoveCategoryUnit } from '@/utils/category'
import { getTypeFromId } from '@/utils/item'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type { UpdateItemParams } from 'dynamodb-helpers'
import { cloneDeep } from 'lodash'
import { SmartGoal, StretchGoal, StretchGoalsOrder } from '@/types/goal'
import { Idea } from '@/types/idea'
import { tagTypes, builderHelpers } from './builder'
import { MascotSettings } from '@/types/panda'
import {
    getOfflineCategories,
    transformPlannerItems,
    updateOfflinePlannerItems,
} from '@/utils/item/offline'
import {
    putOptimisticItemGetCategoryResInPlace,
    putOptimisticItemGetDatedItemsInPlace,
    updateOptimisticGetCategoryResInPlace,
} from '@/utils/item/optimistic'
import { paramsToQueryString } from '@planda/utils'
import { MenstrualCycleInfo } from '@/types/period'

/**
 * TODO: useCategories
 */
// Define a service using a base URL and expected endpoints
export const apiSlice = createApi({
    reducerPath: 'api',
    tagTypes,
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/',
    }),
    endpoints: (builder) => {
        const {
            builderArrayGET,
            builderArrayItemPUT,
            builderArrayItemPATCH,
            builderItemGET,
            builderItemPUT,
            builderItemPATCH,
            defaultInvalidateTags,
        } = builderHelpers(builder)

        return {
            getMenstrualCycleData: builder.query<
                MenstrualCycleInfo,
                void | { start: string; end?: string }
            >({
                query: (params) =>
                    `menstrual?${params ? paramsToQueryString({ start: params.start, end: params.end }) : undefined}`,
                providesTags: [{ type: 'menstrual-cycle' }],
            }),
            putMenstrualCycleLog: builder.mutation<void, { start: string; end?: string }>({
                query: ({ start, end }) => ({
                    url: 'menstrual',
                    method: 'PUT',
                    body: { start, end },
                }),
                invalidatesTags: ['menstrual-cycle'],
                // TODO: optimistic updates
            }),
            getTinyTasks: builderItemGET<{ storage: string }>({
                url: 'quick/tasks',
                tag: 'tiny-tasks',
            }),
            getKanbanSettings: builderItemGET<KanbanSettings>({
                url: 'kanban',
                tag: 'kanban-settings',
            }),
            putKanbanSettings: builderItemPUT<KanbanSettings>({
                url: 'kanban',
                tag: 'kanban-settings',
                getItemEndpointName: 'getKanbanSettings',
            }),
            putTinyTasks: builderItemPUT<{ storage: string }>({
                url: 'quick/tasks',
                tag: 'tiny-tasks',
                keyBy: null,
                getItemEndpointName: 'getTinyTasks',
            }),
            getMascotSettings: builderItemGET<MascotSettings>({
                url: 'mascot',
                tag: 'mascot-settings',
            }),
            updateMascotSettings: builderItemPATCH<MascotSettings>({
                url: 'mascot',
                tag: 'mascot-settings',
                getItemEndpointName: 'getMascotSettings',
                keyBy: null,
            }),
            // should Ideas just be tasks without a .completed? maybe ideas can have an expiry date
            getIdeas: builderArrayGET<Idea>({ url: 'main/idea', tag: 'idea' }),
            putIdea: builderArrayItemPUT<New<Idea>>({
                url: 'main/idea',
                tag: 'idea',
                getArrayEndpointName: 'getIdeas',
            }),
            putStretchGoal: builderArrayItemPUT<StretchGoal>({
                url: 'goal/stretch',
                tag: 'goal',
                getArrayEndpointName: 'getStretchGoals',
            }),
            putSmartGoal: builderArrayItemPUT<SmartGoal>({
                url: (item) => `goal/stretch/${item.stretchGoalId}/smart/${item.id}`,
                tag: 'goal',
                getArrayEndpointName: 'getSmartGoalsOfStretchGoal',
                getArrayEndpointArgs: (item) => item.stretchGoalId,
            }),
            removeStretchGoal: builder.mutation<void, string>({
                query: (stretchGoalId) => {
                    return {
                        url: `goal/stretch/${stretchGoalId}`,
                        method: 'DELETE',
                    }
                },
                invalidatesTags: (result, error, id) => [{ type: 'goal', id }],
                async onQueryStarted(id, { dispatch, queryFulfilled }) {
                    const patchedResults = [
                        dispatch(
                            apiSlice.util.updateQueryData('getStretchGoals', undefined, (data) => {
                                return data.filter((x) => x.id !== id)
                            })
                        ),
                        dispatch(
                            apiSlice.util.updateQueryData('getSmartGoalsOfStretchGoal', id, () => {
                                return []
                            })
                        ),
                    ]
                    patchedResults.forEach((patchedResult) =>
                        queryFulfilled.catch(patchedResult.undo)
                    )
                },
            }),
            removeSmartGoal: builder.mutation<void, { id: string; stretchGoalId: string }>({
                query: (item) => {
                    return {
                        url: `goal/stretch/${item.stretchGoalId}/smart/${item.id}`,
                        method: 'DELETE',
                    }
                },
                invalidatesTags: (result, error, { id }) => [{ type: 'goal', id }],
                async onQueryStarted({ id, stretchGoalId }, { dispatch, queryFulfilled }) {
                    const patchedResults = [
                        dispatch(
                            apiSlice.util.updateQueryData(
                                'getSmartGoalsOfStretchGoal',
                                stretchGoalId,
                                (data) => {
                                    return data.filter((x) => x.id !== id)
                                }
                            )
                        ),
                    ]
                    patchedResults.forEach((patchedResult) =>
                        queryFulfilled.catch(patchedResult.undo)
                    )
                },
            }),
            updateSmartGoal: builderArrayItemPATCH<SmartGoal, { stretchGoalId: string }>({
                url: (item) => `goal/stretch/${item.stretchGoalId}/smart/${item.id}`,
                tag: 'goal',
                getArrayEndpointName: 'getSmartGoalsOfStretchGoal',
            }),
            putStretchGoalsOrder: builder.mutation<void, string[]>({
                query: (item) => ({
                    url: `goal/stretch/order`,
                    method: 'PUT',
                    body: item,
                }),
                invalidatesTags: ['goal-order'],
                async onQueryStarted(newOrder, { dispatch, queryFulfilled }) {
                    const patchedResults = [
                        dispatch(
                            apiSlice.util.updateQueryData(
                                'getStretchGoalsOrder',
                                undefined,
                                (data) => ({ ...data, order: newOrder })
                            )
                        ),
                    ]
                    queryFulfilled.catch(() => {
                        patchedResults.forEach(
                            (patchedResult) => patchedResult && patchedResult.undo()
                        )
                    })
                },
            }),
            getStretchGoalsOrder: builder.query<StretchGoalsOrder, void>({
                query: () => `goal/stretch/order`,
                providesTags: ['goal-order'],
            }),
            getStretchGoals: builderArrayGET<StretchGoal>({ url: `goal/stretch`, tag: 'goal' }),
            getSmartGoalsOfStretchGoal: builderArrayGET<SmartGoal, string>({
                url: (stretchId) => `goal/stretch/${stretchId}/smart`,
                tag: 'goal',
            }),
            getWorkBlockInfo: builder.query<WorkBlockInfo | undefined, string>({
                query: (id: string) => `work-block/${id}`,
                providesTags(result, error, arg, meta) {
                    return [{ type: 'workBlock', id: arg }]
                },
            }),
            addToWorkBlock: builder.mutation<
                void,
                { id: string; taskId: string; taskInfo?: Partial<Task> }
            >({
                query: ({ id, taskId }) => {
                    return {
                        url: `work-block/${id}`,
                        method: 'PATCH',
                        body: { id: id, taskIds: [taskId] },
                    }
                },
                invalidatesTags: (result, error, { id }) => [{ type: 'workBlock', id }],
                async onQueryStarted({ id, taskId, taskInfo }, { dispatch, queryFulfilled }) {
                    const patchedResult = dispatch(
                        apiSlice.util.updateQueryData('getWorkBlockInfo', id, (data) => {
                            const task = { ...taskInfo, type: 'task' } as Task // TODO: is this dangerous?
                            if (data?.taskIds && data.taskIds.includes(taskId)) return data
                            // const task = { ...taskInfo, type: 'task', name: name.startsWith("Work on ") ? name.slice(("Work on ").length) : name }
                            if (!data)
                                return {
                                    id: id,
                                    taskIds: [taskId],
                                    tasks: [task],
                                }
                            return {
                                ...data,
                                tasks: [...(data.tasks || []), task],
                                taskIds: [...(data.taskIds || []), taskId],
                            }
                        })
                    )
                    queryFulfilled.catch(patchedResult.undo)
                },
            }),
            removeFromWorkBlock: builder.mutation<void, { id: string; taskIds: string[] }>({
                query: ({ id, taskIds }) => {
                    taskIds = taskIds.filter(Boolean)

                    return {
                        url: `work-block/${id}`,
                        method: 'DELETE',
                        body: { taskIds },
                    }
                },
                invalidatesTags: (result, error, { id }) => [{ type: 'workBlock', id }],
                async onQueryStarted({ id, taskIds }, { dispatch, queryFulfilled }) {
                    taskIds = taskIds.filter(Boolean)
                    const patchedResult = dispatch(
                        apiSlice.util.updateQueryData('getWorkBlockInfo', id, (data) => {
                            if (!data?.taskIds && !data?.tasks) return data as any
                            return {
                                ...data,
                                tasks: data.tasks?.filter(
                                    (x) => !taskIds.some((taskId) => taskId === x.id)
                                ),
                                taskIds: data.taskIds?.filter(
                                    (x) => !taskIds.some((taskId) => taskId === x)
                                ),
                            }
                        })
                    )
                    queryFulfilled.catch(patchedResult.undo)
                },
            }),
            getCategoryRes: builder.query<CategoryRes, void>({
                query: () => `main/category`,
                providesTags: (result, error, arg, meta) => {
                    const defaultTags = [
                        { type: 'event' },
                        { type: 'task' },
                        { type: 'category' },
                    ] as const
                    if (!result) return defaultTags
                    const itemTags = Object.values(result.items)
                        .flat()
                        .map(({ id, type }) => ({ type, id }))
                    // TODO: tags for category ids
                    return [...itemTags, ...defaultTags]
                },
                async transformErrorResponse(baseQueryReturnValue, meta, arg) {
                    if (baseQueryReturnValue.status === 'FETCH_ERROR') {
                        const [categoryUnits, newItems] = await Promise.all([
                            getOfflineCategories(),
                            transformPlannerItems(undefined, true),
                        ])
                        if (!categoryUnits) return
                        const data = {
                            categoryUnits,
                            items: newItems,
                            parentIdDict: {}, // TODO, persist this with localforage
                        }
                        return { offline: data }
                    }
                    return baseQueryReturnValue
                },
                transformResponse: async (response: CategoryRes) => {
                    if (typeof window !== 'undefined') {
                        import('localforage').then(async ({ default: localforage }) => {
                            localforage.setItem('categories', response.categoryUnits)
                        })
                    }
                    updateOfflinePlannerItems(response.items)
                    return response
                },
            }),
            getDatedItems: builder.query<
                DatedRes,
                { start: number; end: number; convertTemplateRecurs?: boolean }
            >({
                query: ({ start, end, convertTemplateRecurs = false }) =>
                    `date?start=${start}&end=${end}&convertTemplateRecurs=${convertTemplateRecurs}`,
                providesTags: (result, error, { start, end }) => [
                    { type: 'event' },
                    { type: 'task' },
                    ...(result?.map(({ type, id }) => ({ type, id })) || [
                            { type: 'event', id: `${start}-${end}` },
                            { type: 'task', id: `${start}-${end}` },
                            // also possible TemplateRecur
                        ] ||
                        []),
                ],
                async onQueryStarted(
                    { start, end },
                    { dispatch, queryFulfilled, getState, updateCachedData, getCacheEntry }
                ) {
                    const categoryRes = apiSlice.endpoints.getCategoryRes.select()(getState()).data
                    if (categoryRes) {
                        dispatch(
                            // no need to undo this
                            apiSlice.util.updateQueryData(
                                'getDatedItems',
                                { start, end },
                                (draft) => {
                                    if (draft && draft.length > 0) return draft
                                    const datedTasks = categoryRes.items.task.filter(
                                        (x) => x.dateStart
                                    )
                                    return [
                                        ...categoryRes.items.event,
                                        ...datedTasks,
                                        ...categoryRes.items.templateRecur,
                                    ] as DatedItem[]
                                }
                            )
                        )
                    }
                },
            }),
            deletePlannerItem: builder.mutation<void, string>({
                query: (id) => ({
                    url: 'main/item',
                    method: 'DELETE',
                    body: { id },
                }),
                invalidatesTags: (result, error, arg, meta) => [
                    { type: getTypeFromId(arg), id: arg },
                ],
                async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
                    const patchedResults: any[] = []
                    const type = getTypeFromId(id)
                    for (const { endpointName, originalArgs } of apiSlice.util.selectInvalidatedBy(
                        getState(),
                        [{ type, id }]
                    )) {
                        // we only want to update `getPosts` here
                        if (endpointName === 'getCategoryRes') {
                            const res = dispatch(
                                apiSlice.util.updateQueryData(
                                    'getCategoryRes',
                                    undefined,
                                    (draft) => {
                                        const items = draft.items[type]
                                        // @ts-expect-error
                                        draft.items[type] = items.filter((x) => x.id !== id)
                                        return draft
                                    }
                                )
                            )
                            patchedResults.push(res)
                            continue
                        } else if (endpointName === 'getDatedItems') {
                            const res = dispatch(
                                apiSlice.util.updateQueryData(
                                    'getDatedItems',
                                    originalArgs,
                                    (draft) => {
                                        return draft.filter((x) => x.id !== id)
                                    }
                                )
                            )
                            patchedResults.push(res)
                        }
                    }
                    queryFulfilled.catch(() => {
                        patchedResults.forEach((x) => x.undo())
                    })
                },
            }),
            updatePlannerItem: builder.mutation<
                void,
                { id: string; updates: UpdateItemParams<Item> }
            >({
                query: ({ id, updates }) => {
                    const remove =
                        updates.set &&
                        Object.keys(updates.set).filter(
                            (key) => updates.set![key as keyof typeof updates.set] === undefined
                        )
                    if (remove) {
                        if (!updates.remove) updates.remove = []
                        updates.remove = updates.remove.concat(remove)
                    }

                    return {
                        url: 'main/item',
                        method: 'PATCH',
                        body: { ...updates, id },
                    }
                },
                invalidatesTags: (result, error, arg, meta) => [
                    { type: getTypeFromId(arg.id), id: arg.id },
                ],
                async onQueryStarted({ id, updates }, { dispatch, queryFulfilled, getState }) {
                    const patchedResults: any[] = []
                    for (const { endpointName, originalArgs } of apiSlice.util.selectInvalidatedBy(
                        getState(),
                        [{ type: getTypeFromId(id), id: id }]
                    )) {
                        // we only want to update `getPosts` here
                        if (endpointName === 'getCategoryRes') {
                            const res = dispatch(
                                apiSlice.util.updateQueryData(
                                    'getCategoryRes',
                                    undefined,
                                    (draft) => {
                                        updateOptimisticGetCategoryResInPlace(draft, id, updates)
                                    }
                                )
                            )
                            patchedResults.push(res)
                            continue
                        } else if (endpointName === 'getDatedItems') {
                            const res = dispatch(
                                apiSlice.util.updateQueryData(
                                    'getDatedItems',
                                    originalArgs,
                                    (draft) => {
                                        return draft.map((x) =>
                                            x.id === id
                                                ? dynamodbUpdateItemOptimistic(
                                                      cloneDeep(x),
                                                      cloneDeep(updates)
                                                  )
                                                : x
                                        ) as DatedRes
                                    }
                                )
                            )
                            patchedResults.push(res)
                        }
                    }
                    queryFulfilled.catch(() => {
                        patchedResults.forEach((x) => x.undo())
                    })
                },
            }),
            putPlannerItemNLP: builder.mutation<{ success: boolean; item: Item }, { text: string }>(
                {
                    query: (item) => ({
                        url: 'main/item/nlp',
                        method: 'POST',
                        body: item,
                    }),
                    invalidatesTags: (result, error, arg, meta) => [
                        // TODO: not sure how to handle this
                        { type: 'task' },
                        { type: 'event' },
                        { type: 'templateRecur' },
                        { type: 'achievement' },
                    ],
                }
            ),
            putPlannerItem: builder.mutation<
                void,
                { item: NewItem | Item | CalendarItem; isNew?: boolean }
            >({
                query: ({ item }) => ({
                    url: 'main/item',
                    method: 'PUT',
                    body: item,
                }),
                invalidatesTags: (result, error, { item, isNew }, meta) => [
                    // TODO: not sure how to handle this
                    { type: item.type, id: item.id },
                    { type: item.type },
                    {
                        type: 'achievement',
                        id:
                            item.type === 'task'
                                ? 'create-task-with-form'
                                : item.type === 'event'
                                  ? 'create-event-with-form'
                                  : undefined,
                    },
                    // ...(isNew ? [{ type: item.type }] : []),
                ],
                async onQueryStarted(
                    { item: initialItem },
                    { dispatch, queryFulfilled, getState }
                ) {
                    if (!initialItem.id && !initialItem.type) {
                        if ('dateEnd' in initialItem && initialItem.dateEnd) {
                            initialItem.type =
                                'dateEnd' in initialItem &&
                                (initialItem as { dateEnd: number }).dateEnd
                                    ? 'event'
                                    : 'task'
                        }
                    } else if (initialItem.id && !initialItem.type) {
                        initialItem.type = getTypeFromId(initialItem.id)
                    }
                    const item = initialItem as Item | NewItem

                    const patchedResults: any[] = []
                    for (const { endpointName, originalArgs } of apiSlice.util.selectInvalidatedBy(
                        getState(),
                        [{ type: item.type, id: item.id }, { type: item.type }]
                    )) {
                        // we only want to update `getPosts` here
                        if (endpointName === 'getCategoryRes') {
                            const res = dispatch(
                                apiSlice.util.updateQueryData(
                                    'getCategoryRes',
                                    undefined,
                                    (draft) => {
                                        putOptimisticItemGetCategoryResInPlace(draft, item)
                                    }
                                )
                            )
                            patchedResults.push(res)
                            continue
                        } else if (endpointName === 'getDatedItems') {
                            if (isDatedItem(item)) {
                                const res = dispatch(
                                    apiSlice.util.updateQueryData(
                                        'getDatedItems',
                                        originalArgs,
                                        (draft) => {
                                            putOptimisticItemGetDatedItemsInPlace(draft, item)
                                        }
                                    )
                                )
                                patchedResults.push(res)
                            }
                        }
                    }
                    queryFulfilled.catch(() => {
                        patchedResults.forEach((x) => x.undo())
                    })
                },
            }),
            putPlannerItems: builder.mutation<void, { items: NewItem[] }>({
                query: ({ items }) => ({
                    url: 'main/items',
                    method: 'PUT',
                    body: items,
                }),
                invalidatesTags: (result, error, { items }, meta) =>
                    items.map((item) => ({ type: item.type, id: item.id })),
                async onQueryStarted({ items }, { dispatch, queryFulfilled, getState }) {
                    const patchedResults: any[] = []
                    const res = dispatch(
                        apiSlice.util.updateQueryData('getCategoryRes', undefined, (draft) => {
                            items.forEach((item) => {
                                putOptimisticItemGetCategoryResInPlace(draft, item)
                            })
                        })
                    )
                    const args = apiSlice.util.selectCachedArgsForQuery(getState(), 'getDatedItems')
                    patchedResults.push(res)
                    args.map((arg) => {
                        const res = dispatch(
                            apiSlice.util.updateQueryData('getDatedItems', arg, (draft) => {
                                items.forEach((item) => {
                                    putOptimisticItemGetDatedItemsInPlace(draft, item)
                                })
                            })
                        )
                        patchedResults.push(res)
                    })

                    queryFulfilled.catch(() => {
                        patchedResults.forEach((x) => x.undo())
                    })
                },
            }),
            putCategoryUnit: builder.mutation<
                void,
                {
                    dbProperties: Partial<Category> | Partial<Section>
                    path?: string
                }
            >({
                query: ({ path, dbProperties }) => ({
                    url: 'main/categoryUnit',
                    method: 'POST',
                    body: {
                        path,
                        ...dbProperties,
                    },
                }),
                invalidatesTags: (result, error, arg, meta) => [
                    // TODO: not sure how to handle this
                    { type: 'categoryUnit', id: arg.path },
                    { type: 'achievement', id: 'category-unit-create-section' },
                    { type: 'achievement', id: 'category-unit-create-category' },
                ],
                async onQueryStarted({ path, dbProperties }, { dispatch, queryFulfilled }) {
                    let { isCategory, isSection, isGroup } = getInfoFromCategoryPath(path)
                    const id = dbProperties.id

                    if (id) {
                        isGroup = false
                        isSection = false
                        isCategory = false
                        const [_, categoryId, groupId] = getCategoryPath(id)
                        if (!categoryId) isSection = true
                        else if (!groupId) isCategory = true
                        else isGroup = true
                        const split = id.split('/')
                        split.pop()
                        path = split.join('/')
                    }

                    const patchedResults = [
                        dispatch(
                            apiSlice.util.updateQueryData('getCategoryRes', undefined, (x) => {
                                if (!x) return getEmptyCategoryRes()
                                if ('id' in dbProperties) {
                                    // if id already exists, replace old item, normally use patch though
                                    let categoryUnitsSamePath = isCategory
                                        ? x.categoryUnits?.categories[path!]
                                        : isSection
                                          ? x.categoryUnits?.sections
                                          : x.categoryUnits?.groups[path!]
                                    const index = categoryUnitsSamePath?.findIndex(
                                        (c) => c.id === dbProperties.id
                                    )
                                    if (typeof index === 'number' && index >= 0) {
                                        categoryUnitsSamePath[index] = dbProperties as
                                            | Section
                                            | Category
                                            | TodoGroup
                                        return
                                    }
                                }

                                const CATEGORY_UNIT_DEFAULTS = {
                                    ...(!isGroup && { active: 1 }),
                                    createdAt: Date.now(),
                                    updatedAt: Date.now(),
                                }

                                const categoryUnitId = id || (path ? path + '/' : '') + 'new'
                                if (isCategory) {
                                    x.categoryUnits?.categories[path!].push(
                                        CategorySchema.parse({
                                            ...dbProperties,
                                            id: categoryUnitId,
                                            ...CATEGORY_UNIT_DEFAULTS,
                                        })
                                    )
                                    x.categoryUnits.groups[categoryUnitId] = []
                                } else if (isSection) {
                                    x.categoryUnits?.sections.push(
                                        SectionSchema.parse({
                                            ...dbProperties,
                                            id: categoryUnitId,
                                            ...CATEGORY_UNIT_DEFAULTS,
                                        })
                                    )
                                    x.categoryUnits.categories[categoryUnitId] = []
                                } else if (isGroup) {
                                    x.categoryUnits?.groups[path!].push(
                                        TodoGroupSchema.parse({
                                            ...dbProperties,
                                            id: categoryUnitId,
                                            ...CATEGORY_UNIT_DEFAULTS,
                                        })
                                    )
                                }
                            })
                        ),
                    ]
                    patchedResults.forEach((patchedResult) =>
                        queryFulfilled.catch(patchedResult.undo)
                    )
                },
            }),
            editCategoryUnit: builder.mutation<
                void,
                { path: string; properties: Record<string, any> }
            >({
                query: ({ path, properties }) => {
                    const updates = parseCategoryUnitUpdates(path, properties)
                    return {
                        url: 'main/categoryUnit',
                        method: 'PATCH',
                        body: {
                            id: path,
                            updates: { set: updates },
                        },
                    }
                },
                invalidatesTags: (result, error, arg, meta) => [
                    // TODO: not sure how to handle this
                    { type: 'categoryUnit', id: arg.path },
                ],
                async onQueryStarted({ path, properties }, { dispatch, queryFulfilled }) {
                    const patchedResult = dispatch(
                        apiSlice.util.updateQueryData(
                            'getCategoryRes',
                            undefined,
                            (x: CategoryRes) => {
                                getCategoryOptimisticData(path, cloneDeep(properties), x)
                            }
                        )
                    )
                    queryFulfilled.catch(patchedResult.undo)
                },
            }),
            removeCategoryUnit: builder.mutation<void, { path: string }>({
                query: ({ path }) => ({
                    url: 'main/categoryUnit',
                    method: 'DELETE',
                    body: {
                        id: path,
                    },
                }),
                invalidatesTags: (result, error, arg, meta) => [
                    // TODO: not sure how to handle this
                    { type: 'categoryUnit', id: arg.path },
                ],
                async onQueryStarted({ path }, { dispatch, queryFulfilled }) {
                    const patchedResult = dispatch(
                        apiSlice.util.updateQueryData(
                            'getCategoryRes',
                            undefined,
                            (x: CategoryRes) => {
                                shallowRemoveCategoryUnit(path, x)
                            }
                        )
                    )
                    queryFulfilled.catch(patchedResult.undo)
                },
            }),
            inactivateCategoryUnit: builder.mutation<void, { path: string; activity?: number }>({
                query: ({ path, activity = 0 }) => ({
                    url: 'main/categoryUnit',
                    method: 'OPTIONS',
                    body: {
                        id: path,
                        activity,
                    },
                }),
                invalidatesTags: (result, error, arg, meta) => [
                    // TODO: not sure how to handle this
                    { type: 'categoryUnit', id: arg.path },
                ],
                async onQueryStarted({ path, activity = 0 }, { dispatch, queryFulfilled }) {
                    const patchedResult = dispatch(
                        apiSlice.util.updateQueryData(
                            'getCategoryRes',
                            undefined,
                            (x: CategoryRes) => {
                                x = getCategoryOptimisticData(path, { active: activity }, x)
                                if (!activity) x = shallowRemoveCategoryUnit(path, x)
                            }
                        )
                    )
                    queryFulfilled.catch(patchedResult.undo)
                },
            }),
        }
    },
})
