import { format } from 'date-fns'
import { token } from 'styled-system/tokens'
import { z } from 'zod'
import { toDayStringFormat, parseFromDateString } from '@planda/utils'
import { dayRecurrenceSchema } from '@/lib/zod'

// gray for this day doesn't count (ex. every other day habits, or habits that are not daily)
export enum HabitDot {
    UNSET = 0,
    GREEN = 1,
    RED = 2,
    GRAY = 3,
    BLUE = 4,
}

export const HabitDotEnum = z.nativeEnum(HabitDot)
export const DOT_TO_COLOR = {
    [HabitDot.UNSET]: 'transparent',
    [HabitDot.GREEN]: token('colors.$success9'),
    [HabitDot.RED]: token('colors.$error9'),
    [HabitDot.GRAY]: token('colors.$gray7'),
    [HabitDot.BLUE]: token('colors.$success7'),
}

// export type HabitDot = z.infer<typeof HabitDotEnum>

// first we load the habits, for the short version in homepage

export const SingleHabitSchema = z.object({
    id: z.string(),
    name: z.string(), // ex Sleep early
    // targets can be raised over time
    target: z.string(), // ex. Sleep before 10pm
    targets: z
        .array(
            z.object({
                target: z.string(),
                daysOfWeek: z.number().array(),
            })
        )
        .optional(),
    level: z.number(), // starts at 0. raise bar when you get 20 green dots in a row
    hide: z.boolean().optional(),
    createdAt: z.number(),
    updatedAt: z.number(),
    symbol: z.string().optional(), // emoji
    previously: z.array(z.string()).optional(), // previous targets
    recurrence: dayRecurrenceSchema.optional(),
    status: z.enum(['deleted']).optional(),
})

export type SingleHabit = z.infer<typeof SingleHabitSchema>

// we get habits by date-range
export const HabitEntrySchema = z.object({
    id: z.string(), // HABIT_E#id#YYYY-MM-DD
    habitId: z.string(), // (for local use)
    dot: HabitDotEnum,
    date: z.number(),
    time: z.string(), // HH:MM
})
export const DBHabitEntrySchema = HabitEntrySchema.omit({ habitId: true, date: true })
type DBHabitEntry = z.infer<typeof DBHabitEntrySchema>

export const toHabitEntryId = (habitId: string, date: Date | number | string = Date.now()) =>
    `HABIT_E#${habitId}#${toDayStringFormat(new Date(date))}`

export const habitEntryIdToHabitId = (habitEntryId: string) => habitEntryId.split('#')[1]

export const dbHabitEntryToClient = (dbHabitEntry: DBHabitEntry | HabitEntry): HabitEntry => {
    const [_, habitId, date] = dbHabitEntry.id.split('#')
    // @ts-expect-error
    if (dbHabitEntry.userId) {
        // @ts-expect-error
        delete dbHabitEntry.userId
    }
    const parsedDate = parseFromDateString(date)!
    return {
        ...dbHabitEntry,
        habitId,
        date: parsedDate.getTime(),
    }
}

export type HabitEntry = z.infer<typeof HabitEntrySchema>
// query by between HABIT_E#id#start date and HABIT_E#id#end date

export type HabitsRes = {
    habits: SingleHabit[]
    entries: Record<string, HabitEntry[]>
    earliestEntryDate?: number
}

export const getDefaultHabitsRes = (): HabitsRes =>
    ({
        habits: [],
        entries: {},
    }) as const

export const toNewHabitEntry = (
    habitId: string,
    dot: HabitDot,
    date: Date | number
): HabitEntry => ({
    id: toHabitEntryId(habitId, date),
    habitId,
    dot,
    date: new Date(date).getTime(),
    time: format(date, 'HH:mm'),
})

export const HabitOrderSchema = z.object({
    id: z.literal('HABIT_ORDER'),
    order: z.string().array(),
})

export type HabitOrder = z.infer<typeof HabitOrderSchema>
