import { paramsToQueryString } from '@planda/utils'
import { builderHelpers, MutationLifeCycleApi } from './builder'
import {
    HabitsRes,
    HabitEntry,
    SingleHabit,
    dbHabitEntryToClient,
    habitEntryIdToHabitId,
} from '@/features/habit-tracker/types'
import { gameApiSlice } from './gameSlice'
import { StretchGoalsOrder } from '@/types/goal'

export const habitsSlice = gameApiSlice.injectEndpoints({
    endpoints: (builder) => {
        const { builderItemGET, builderArrayGET, defaultInvalidateTags } = builderHelpers(builder)

        const updateHabitsRes = (
            update: (data: HabitsRes) => void,
            { dispatch, queryFulfilled, getState }: MutationLifeCycleApi,
            tags: Parameters<typeof habitsSlice.util.selectInvalidatedBy>[1]
        ) => {
            const patchedResults: any[] = []
            for (const { endpointName, originalArgs } of habitsSlice.util.selectInvalidatedBy(
                getState(),
                tags
            )) {
                if (endpointName === 'getHabitsRes') {
                    patchedResults.push(
                        dispatch(
                            habitsSlice.util.updateQueryData('getHabitsRes', originalArgs, update)
                        )
                    )
                }
            }
            queryFulfilled.catch(() => {
                patchedResults.forEach((patchedResult) => patchedResult && patchedResult.undo?.())
            })
        }

        return {
            getHabits: builderArrayGET<SingleHabit, void>({ url: 'habit', tag: 'habit' }),
            getHabit: builderItemGET<SingleHabit, string>({
                url: (id) => `habit/${id}/settings`,
                tag: 'habit',
            }),
            getHabitsRes: builder.query<HabitsRes, { start?: number; end?: number }>({
                query: ({ start, end }) => `habit/full?${paramsToQueryString({ start, end })}`,
                providesTags: (result) => {
                    const defaultTags = [{ type: 'habit-entry' }, { type: 'habit' }] as const
                    if (!result) return defaultTags
                    return [
                        ...result.habits.map((x) => ({ type: 'habit', id: x.id }) as const),
                        ...Object.values(result.entries)
                            .flat()
                            .map((x) => ({ type: 'habit-entry', id: x.id }) as const),
                        ...defaultTags,
                    ]
                },
                transformResponse(habitRes: HabitsRes) {
                    for (let key in habitRes.entries) {
                        // this is to fix timezones
                        habitRes.entries[key] = habitRes.entries[key].map(dbHabitEntryToClient)
                    }
                    return habitRes
                },
            }),
            putHabit: builder.mutation<void, SingleHabit>({
                query: (x) => {
                    return {
                        url: `habit/${x.id}/settings`,
                        method: 'PUT',
                        body: x,
                    }
                },
                // @ts-expect-error
                invalidatesTags: defaultInvalidateTags<SingleHabit>('habit', 'id'),
                async onQueryStarted(habit, mutationLifeCycleApi) {
                    updateHabitsRes(
                        (data) => {
                            const index = data.habits.findIndex((x) => x.id === habit.id)
                            if (index >= 0) {
                                data.habits[index] = habit
                            } else {
                                data.habits.push(habit)
                            }
                        },
                        mutationLifeCycleApi,
                        [{ type: 'habit' }]
                    )
                },
            }),
            deleteHabit: builder.mutation<void, { id: string }>({
                query: ({ id }) => ({
                    url: `habit/${id}/settings`,
                    method: 'DELETE',
                }),
                invalidatesTags: (result, error, { id }) => [
                    { type: 'habit', id },
                    { type: 'habit' },
                ],
                async onQueryStarted({ id }, mutationLifeCycleApi) {
                    updateHabitsRes(
                        (data) => {
                            if (!data) return data
                            data.habits = data.habits.filter((h) => h.id !== id)
                            delete data.entries[id]
                            return data
                        },
                        mutationLifeCycleApi,
                        [{ type: 'habit' }]
                    )
                },
            }),
            putHabitEntry: builder.mutation<void, HabitEntry>({
                query: (item) => {
                    return {
                        url: `habit/${item.habitId}/entry`,
                        method: 'PUT',
                        body: item,
                    }
                },
                // @ts-expect-error
                invalidatesTags: defaultInvalidateTags<SingleHabit>('habit-entry', 'id'),
                async onQueryStarted(habitEntry, mutationLifeCycleApi) {
                    updateHabitsRes(
                        (data) => {
                            if (!data.entries[habitEntry.habitId]) {
                                data.entries[habitEntry.habitId] = []
                            }
                            const index = data.entries[habitEntry.habitId].findIndex((x) => {
                                return x.id === habitEntry.id
                            })
                            if (index >= 0) {
                                data.entries[habitEntry.habitId][index].dot = habitEntry.dot
                            } else {
                                data.entries[habitEntry.habitId].push(habitEntry)
                            }
                        },
                        mutationLifeCycleApi,
                        [{ type: 'habit-entry' }]
                    )
                },
            }),
            putHabitsOrder: builder.mutation<void, string[]>({
                query: (item) => ({
                    url: `habit/order`,
                    method: 'PUT',
                    body: item,
                }),
                async onQueryStarted(newOrder, { dispatch, queryFulfilled }) {
                    const patchedResults = [
                        dispatch(
                            habitsSlice.util.updateQueryData(
                                'getHabitsOrder',
                                undefined,
                                (data) => ({
                                    ...data,
                                    order: newOrder,
                                })
                            )
                        ),
                    ]
                    queryFulfilled.catch(() => {
                        patchedResults.forEach(
                            (patchedResult) => patchedResult && patchedResult.undo()
                        )
                    })
                },
            }),
            getHabitsOrder: builder.query<StretchGoalsOrder, void>({
                query: () => `habit/order`,
            }),
            // TODO: getHabitEntries api
            // getHabitEntries: builder.query<HabitEntry[], { id: string; start?: number; end?: number }>({
            //     query: ({ id, start, end }) => `habit/${id}/entries?${paramsToQueryString({ start, end })}`,
            //     providesTags: (result = []) => {
            //         const defaultTags = [{ type: 'habit-entry' }] as const
            //         return [...result.map((x) => ({ type: 'habit-entry', id: x.id }) as const), ...defaultTags]
            //     },
            // }),
            // builderArrayItemPUT<SingleHabit>({
            //     url: (x) => `habit/${x.id}/settings`,
            //     tag: 'habit',
            //     getItemEndpointName: 'getHabit',
            //     keyBy: 'id',
            // }),
            // putHabitEntry: builderArrayItemPUT<HabitEntry>({
            //     url: ({ habitId }) => `habit/${habitId}/entry`,
            //     tag: 'habit-entry',
            //     getArrayEndpointName: 'getHabitEntries',
            //     updateArgs(arg, ogArg: { id: string; start?: number; end?: number }) {
            //         return ogArg.id === arg.habitId
            //     },
            // }),

            // builder.mutation<void, HabitEntry>({
            //     query: (item) => ({
            //         url: `habit/${item.habitId}/entry`,
            //         method: 'PUT',
            //         body: item,
            //     }),
            //     invalidatesTags: (_, __, arg) => [{ type: 'habit-entry', id: arg.id }, { type: 'habit-entry' }],
            //     async onQueryStarted(item, { dispatch, queryFulfilled, getState }) {
            //         const patchedResults: any[] = []
            //         for (const { endpointName, originalArgs } of habitsSlice.util.selectInvalidatedBy(getState(), [
            //             { type: 'habit-entry', id: item.id },
            //         ])) {
            //             if (endpointName === 'getHabitEntries' && originalArgs.id === item.habitId) {
            //                 const res = dispatch(
            //                     habitsSlice.util.updateQueryData('getHabitEntries', originalArgs, (data) => {
            //                         const index = data.findIndex((x) => x.id === item.id)
            //                         if (index >= 0) {
            //                             data[index] = item
            //                         } else {
            //                             data.push(item)
            //                         }
            //                     })
            //                 )
            //                 patchedResults.push(res)
            //             }
            //         }

            //         queryFulfilled.catch(() => {
            //             patchedResults.forEach((patchedResult) => patchedResult && patchedResult.undo())
            //         })
            //     },
            // }),
            // putHabitEntry: builderItemPUT<HabitEntry, { id: string; date: string | number }>({
            //     url: ({ id, date }) => `habit/${id}/entry/${date}`,
            //     tag: 'habit-entry',
            //     keyBy: 'id',
            //     getItemEndpointName: 'getHabit'
            // }),
            // builderArrayGET<{ id: string, start: number, end: number }>({ url: 'habit/entry', tag: 'habit-entry' }),
        }
    },
})
