import { differenceInCalendarDays } from 'date-fns'
import EventCronParser from 'event-cron-parser'
import { isSet } from 'lodash'
import { z } from 'zod'

export const cronSchema = z.custom<string>((val: any) => {
    if (typeof val !== 'string') return false
    try {
        new EventCronParser(val).validate()
        return true
    } catch (e) {
        console.error('invalid cron', e, val)
        return false
    }
})

export const cronRateSchema = z.discriminatedUnion('formatCron', [
    z.object({
        formatCron: z.literal(0),
        rateIntervalDays: z.number().positive(),
    }),
    z.object({
        daysOfWeek: z
            .array(z.coerce.number().gte(0).lt(7))
            .min(1, { message: 'Select at least one' })
            .optional(),
        formatCron: z.literal(1),
    }),
])

export type FormCronRate = z.infer<typeof cronRateSchema>

export const clientArrayToDBSetSchema = <T extends string>(Enum: z.ZodType<T>) =>
    z.preprocess((x) => (x instanceof Set ? [...x] : x), z.array(Enum)) // should be stored as a set
export const clientArrayToDBSetSchemaOptional = <T extends string>(Enum: z.ZodType<T>) =>
    z.preprocess((x) => (x instanceof Set ? [...x] : x), z.array(Enum).optional()) // should be stored as a set

export const dayRecurrenceSchema = z
    .object({
        // NOTE: DIFFERENT FROM AWS days of week, starts at 0
        daysOfWeek: z
            .array(z.coerce.number().gte(0).lt(7))
            .min(1, { message: 'Select at least one' }),
    })
    .or(z.object({ onceEveryXDays: z.number().int().gte(1) }))

export type DayRecurrence = z.infer<typeof dayRecurrenceSchema>
export const recurrenceOccursOnDate = (
    recur: DayRecurrence,
    date: Date | number | string,
    startingFrom?: Date | number | string
) => {
    const d = new Date(date)
    if ('daysOfWeek' in recur) {
        return recur.daysOfWeek.includes(d.getDay())
    }
    if (!startingFrom) return true
    const daysBetween = differenceInCalendarDays(d, startingFrom)
    if (daysBetween < 0) return false
    return daysBetween % recur.onceEveryXDays === 0
}

export const recurrenceOccursInRange = (
    recur: DayRecurrence,
    start: Date | number | string,
    end: Date | number | string
) => {
    const s = new Date(start)
    const e = new Date(end)
    for (let d = s; d <= e; d.setDate(d.getDate() + 1)) {
        if (recurrenceOccursOnDate(recur, d, s)) return true
    }
    return false
}

export const arrayClientSetDBSchema = {
    client: z.preprocess((val) => (isSet(val) ? [...val] : val), z.array(z.string())),
    db: z.preprocess((val) => (Array.isArray(val) ? new Set(val) : val), z.set(z.string())),
}

export default z
