import { State } from './featureAccessSlice'
import { Plugin } from '@/types'
import { UserFeatureAccess } from '@/types/game/featureAccess'

// store feature access, 0-unlocked but unused 1-unlocked and used
// don't store locked features
export const featureAccessInitialState: State = {
    featureAccessMap: {
        'i.task-difficulty': true,
        kanban: true,
        'syllabus-parser': true,
        'student-grades': false,
        // 'completed-tasks-view': true,
        // 'i.task-estimated-time': true,
        // chronotype: false,
        // 'power-hours': false,
    },
    plugins: {
        alpha: false,
        student: false,
        beta: false,
        ubc: false,
        mobileApp: false, // if connected to mobile app
    },
}

export const PLUGINS_TO_FEATURES: {
    [key in Plugin]?: Pick<UserFeatureAccess, 'enabled' | 'unlocked'>
} = {
    alpha: { enabled: [], unlocked: ['capture-list'] },
    beta: { enabled: ['brain-dump'], unlocked: ['rule-of-three', 'weekly-list', 'weather-panda'] },
}
