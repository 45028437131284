import { LessonConfig } from './types'

const WEATHER_PANDA_CONFIG: LessonConfig = {
    title: 'Weather panda',
    description:
        'Shows different pandas according to weather outside. Requires location permission.',
    visual: {
        type: 'image',
        imgSrc: 'coming-soon.jpg',
        width: 1280,
        height: 854,
    },
    featuresUnlocked: ['weather-panda'],
    challenges: [],
}

export default WEATHER_PANDA_CONFIG
